<template>


  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_v2"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >  
      
      <b-modal
                ref="my-modal"
                hide-footer
                backdrop="static"
                :keyboard="false"
                title="Configurazione simboli"
              >
                <div class="d-block text-center">
                  <h3>Ciao e benvenuto su Alpha4Charts, non tutti i traders sono uguali, abbiamo quindi pensando di lasciare a te la preferenza</h3>
                </div><br>


                <b-form-group style="font-size: 20px;" color="primary" align="center" class="mt-2" label="Seleziona la tipologia di simboli preferita:">
                      <b-form-radio v-model="selected" name="some-radios" value="B"><b-img style="width: 200px;"
                                src="https://app.charts.alpha4all.it/assets/media/logos/logo_screen_old.png"
                                fluid
                                alt="Responsive image"
                              ></b-img><br>I simboli sono rappresentati dal ticker: AD2025H, etc.  </b-form-radio>
                    <b-form-radio v-model="selected" name="some-radios" value="A"><b-img style="width: 200px;"
                                src="https://toptradereviews.com/wp-content/uploads/2019/04/Barchart.com-Review.jpg"
                                fluid
                                alt="Responsive image"
                              ></b-img><br>I simboli sono rappresentati dal ticker: A6H25, etc. </b-form-radio>
                     <b-form-radio v-model="selected" name="some-radios" value="C"><b-img style="width: 200px;"
                                src="https://www.interactivebrokers.co.uk/images/web/logos/ib-logo-text-black.svg"
                                fluid
                                alt="Responsive image"
                              ></b-img><br>I simboli sono rappresentati dal ticker: AUDH25, etc.  </b-form-radio>
                      <b-form-radio v-model="selected" name="some-radios" value="D"><b-img style="width: 200px;"
                                src="https://www.interactivebrokers.co.uk/images/web/logos/ib-logo-text-black.svg"
                                fluid
                                alt="Responsive image"
                              ></b-img><br>I simboli sono rappresentati dal ticker: AUD2025H, etc.  </b-form-radio>
                  </b-form-group>
         <b-button
                  class="mt-3"
                  variant="outline-danger"
                  block
                  @click="hideModal"
                >
                  Chiudi
                </b-button>
                <b-button
                  class="mt-2"
                  variant="outline-warning"
                  block
                  @click="toggleModal"
                >
                  Salva
                </b-button>
              </b-modal>
      </div>
      <!--end: Wizard-->
    </div>
  </div>

                 


</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";

</style>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";

export default {
  name: "Wizard-2",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard", route: "wizard-1" },
      { title: "Wizard-2" }
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v2", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function(/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });

    this.showModal()
  },
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });
    }
  }
};
</script>
