// import { createInitialState } from '@/js/createInitialState.js';

const addToPortfolioTourMixin = {
    mounted() {
        console.log("addToPortfolioTourMixin.js mounted() starting. this.namespace=", this.namespace);
    },
    data: function(){
        return {
            last: null,
            ticksize: null,
            futuresTabIndex: null
        }
    },
    computed: {
        portfolioSteps() {
            let that = this;
            return  [
                {
                    target: '#tour-start-' + this.namespace, 
                    header: {
                        title: 'Add-To-Portfolio Tour',
                    },
                    content: `<p style="text-align: left">We start by opening a Futures chart.</p>
                        <p style="text-align: left">You may use the ←, → and ESC keys to navigate through the tour.</p>`,
                    params: {
                        enableScrolling: false
                    },
                    before: (type) => {
                        console.log("type=", type);

                        if(type === 'start'){
                            let initializingState = [];
                            that.$store.commit("user/setInitializingState", initializingState);
                            that.$store.dispatch('newTab', "BasicChartsNew");

                        }
                    }
                },
                {
                    target: '#add-to-portfolio-button-' + that.activeModuleName,
                    content: `<p style="text-align: left">Push this button to add this trade to the portfolio. This button is also on the Calculator program.</p>`,

                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    before: (type) => {
                        // 'type' can be 'start', 'nextStep', or 'previousStep'
                        console.log("type=", type);
                        return new Promise((resolve) => {
                            console.log("that.$store.state.activeModuleIndex=", that.$store.state.activeModuleIndex);

                            that.futuresTabIndex = that.$store.state.activeModuleIndex;
                            console.log("that.futuresTabIndex=", that.futuresTabIndex);

                            if(type === 'previous'){
                                that.$refs.content.$refs.tabsBox.$refs[that.activeModuleName + '-basic-chart-output'][0].$refs['add-to-portfolio-button-'+that.activeModuleName].hideModal();                     
                            }

                             setTimeout(function () {
                                console.log("that.$refs=", that.$refs);

                                 resolve('foo');
                             }, 500);
                        });
                    } 
                },
                {
                    target: '#add-to-portfolio-folders-' + that.activeModuleName,
                    content: `<p style="text-align: left">These are the folders that are available in the Portfolio. Select the one you want to add the trade to, or...</p>`,
                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    before: (type) => {
                        // 'type' can be 'start', 'nextStep', or 'previousStep'
                        console.log("type=", type);
                        return new Promise((resolve) => {

                            if(type === 'next'){
                                that.$refs.content.$refs.tabsBox.$refs[that.activeModuleName + '-basic-chart-output'][0].$refs['add-to-portfolio-button-'+that.activeModuleName].showModal();                     
                            } 
                            
                            setTimeout(function () {
                                console.log("that.$refs=", that.$refs);

                                 resolve('foo');
                             }, 500);
                        });
                    }                    
                },
                {
                    target: '#add-to-portfolio-add-folder-' + that.activeModuleName,
                    content: `<p style="text-align: left">Click here if you would like to add a new folder to the Portfolio.</p>`,
                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    }                
                },
                {
                    target: '#add-to-portfolio-new-folder-' + that.activeModuleName,
                    content: `<p style="text-align: left">We are going to create a new folder named 'Tour'.</p>`,
                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    before: () => {
                        // 'type' can be 'start', 'nextStep', or 'previousStep'

                      /* console.log("that.$refs.content.$refs.tabsBox.$refs[that.activeModuleName + '-basic-chart-output'][0].$refs['add-to-portfolio-button-'+that.activeModuleName].$refs['portfolio-folders-'+that.activeModuleName]=", 
                        that.$refs.content.$refs.tabsBox.$refs[that.activeModuleName + '-basic-chart-output'][0].$refs['add-to-portfolio-button-'+that.activeModuleName].$refs['portfolio-folders-'+that.activeModuleName]);
                      */

                        return new Promise((resolve) => {

                        that.$refs.content.$refs.tabsBox.$refs[that.activeModuleName + '-basic-chart-output'][0].$refs['add-to-portfolio-button-'+that.activeModuleName]
                            .$refs['portfolio-folders-'+that.activeModuleName].showNewFolderNameInput();
                        
                        that.$refs.content.$refs.tabsBox.$refs[that.activeModuleName + '-basic-chart-output'][0].$refs['add-to-portfolio-button-'+that.activeModuleName]
                            .$refs['portfolio-folders-'+that.activeModuleName].newFolder = "Tour";                
                    
                            setTimeout(function () {
                                 resolve('foo');
                             }, 50);
                        });
                    }            
                },
                {
                    target: '#add-to-portfolio-new-folder-add-button-' + that.activeModuleName,
                    content: `<p style="text-align: left">Then click the Add button to create the folder.</p>`,
                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    before: (type) => {
                        if(type === "previous"){          
                            that.$refs.content.$refs.tabsBox.$refs[that.activeModuleName + '-basic-chart-output'][0].$refs['add-to-portfolio-button-'+that.activeModuleName]
                            .$refs['portfolio-folders-'+that.activeModuleName].showNewFolderNameInput();
                        }

                        return new Promise((resolve) => {
                            setTimeout(function () {
                                 resolve('foo');
                            }, 200);
                        });
                    }
                },
                {
                    target: '#add-to-portfolio-add-button-' + that.activeModuleName,
                    content: `<p style="text-align: left">Click here to add the trade to the selected folder.</p>`,
                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    before: () => {
                        // 'type' can be 'start', 'nextStep', or 'previousStep'

                      that.$refs.content.$refs.tabsBox.$refs[that.activeModuleName + '-basic-chart-output'][0].$refs['add-to-portfolio-button-'+that.activeModuleName]
                        .$refs['portfolio-folders-'+that.activeModuleName].addNewFolder();                                    
                    }                                
                },
                {
                   target: '#go-to-portfolio-button-' + that.activeModuleName,
                   content: `<p style="text-align: left">Click here to go to the Portfolio.</p>`,
                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    },
                    before: (type) => {
                        if(type === "next"){
                        console.log("that.$refs.content.$refs.tabsBox.$refs[that.activeModuleName + '-basic-chart-output'][0].$refs=", that.$refs.content.$refs.tabsBox.$refs[that.activeModuleName + '-basic-chart-output'][0].$refs['add-to-portfolio-button-'+that.activeModuleName]);
                        that.$refs.content.$refs.tabsBox.$refs[that.activeModuleName + '-basic-chart-output'][0].$refs['add-to-portfolio-button-'+that.activeModuleName].createTrade();
                        
                        return new Promise((resolve) => {
                            setTimeout(function () {
                                 resolve('foo');
                            }, 800);
                        });
                    } else if(type === "previous"){
                                console.log("that.futuresTabIndex=", that.futuresTabIndex);
                                this.$store.commit("setActiveModuleIndex", that.futuresTabIndex);
                                that.$refs.content.$refs.tabsBox.$refs[that.activeModuleName + '-basic-chart-output'][0].$refs['add-to-portfolio-button-'+that.activeModuleName].showModal();   
                                that.$refs.content.$refs.tabsBox.$refs[that.activeModuleName + '-basic-chart-output'][0].$refs['add-to-portfolio-button-'+that.activeModuleName].result = "success";
                             /*   that.$refs.content.$refs.tabsBox.$refs[that.activeModuleName + '-basic-chart-output'][0].$refs['add-to-portfolio-button-'+that.activeModuleName]
                            .$refs['portfolio-folders-'+that.activeModuleName].showNewFolderName = true; */
                            return new Promise((resolve) => {
                                setTimeout(function () {
                                     resolve('foo');
                                }, 2000);
                            });
                        }
                       
                        
                    }                    
                }
            ];
        },
    }
};

export default addToPortfolioTourMixin;