<template>
  <div class="d-flex flex-column flex-root" style="overflow: hidden scroll;">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->
      <Wizard></Wizard>
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content 
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid" 

        >-->
        <div id="kt_content" class="d-flex flex-column flex-column-fluid">
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <!-- end:: Content Head -->


          <v-tour name="siteTour" :steps="siteTourSteps" :options="{ debug: true, highlight: false }" ref="site-tour" :callbacks="tourCallbacks">
            <template slot-scope="tour">
                <transition name="fade">
                    <v-step
                        v-if="tour.steps[tour.currentStep]"
                        :key="tour.currentStep"
                        :step="tour.steps[tour.currentStep]"
                        :previous-step="tour.previousStep"
                        :next-step="tour.nextStep"
                        :stop="tour.stop"
                        :skip="tour.skip"
                        :is-first="tour.isFirst"
                        :is-last="tour.isLast"
                        :labels="tour.labels"
                        >
                        <template v-if="tour.currentStep === 4">
                            <div slot="actions">
                                <button @click="tour.previousStep" style="border: 1px solid white; margin: 2px; padding: 3px; font-size: .85rem;">Previous step</button>
                                <button @click="tour.nextStep" style="border: 1px solid white; margin: 2px;  padding: 3px; font-size: .85rem;">Next step</button>
                                <button @click="switchToSavingTabsTour" style="border: 1px solid white; margin: 2px;  padding: 3px; font-size: .85rem;">
                                    Switch to "Saving Tabs" tour</button>
                            </div>
                        </template>

                        <!--   <template v-if="tour.currentStep === 10">
                                   <div slot="actions">
                                       <button @click="tour.previousStep" style="border: 1px solid white; margin: 2px; padding: 3px; font-size: .85rem;">Previous step</button>
                                       <button @click="tour.stop" style="border: 1px solid white; margin: 2px;  padding: 3px; font-size: .85rem;">Finish</button>                                    
                                   </div>
                               </template> -->
                    </v-step>
                </transition>
            </template>
        </v-tour>

        <v-tour name="savingTabsTour" :steps="savingTabsSteps" :options="{ debug: true }" ref="saving-tabs-tour" :callbacks="tourCallbacks"></v-tour>

        <v-tour name="tickerInputTextTour" :steps="tickerInputTextSteps" :options="{ debug: true }" ref="ticker-input-text-tour" :callbacks="tourCallbacks"></v-tour>

        <v-tour name="addToPortfolioTour" :steps="portfolioSteps" :options="{ debug: true }" ref="add-to-portfolio-tour" :callbacks="tourCallbacks"></v-tour>

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid" style="margin: 0px -25px;">
            <div style="margin-top: 0px;"
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
              
            >
              <transition name="fade-in-up">
                <router-view ref="content"/>
              </transition>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <!--KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar
    <KTScrollTop></KTScrollTop> -->
  </div>
</template>

<script>

    import { mapGetters } from "vuex";
    import Loader from "@/view/content/Loader.vue";
    import KTAside from "@/view/layout/aside/Aside.vue";
    import KTHeader from "@/view/layout/header/Header.vue";
    import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
    import KTFooter from "@/view/layout/footer/Footer.vue";
    import HtmlClass from "@/core/services/htmlclass.service";
    import * as fb from '@/firebase';
    import moment from "moment";
    import tickerInputTextTourMixin from '@/view/layout/tourMixinsForLayout/tickerInputTextTourMixin.js';
    import siteTourMixin from '@/view/layout/tourMixinsForLayout/siteTourMixin.js';
    import savingTabsTourMixin from '@/view/layout/tourMixinsForLayout/savingTabsTourMixin.js';
    import addToPortfolioTourMixin from '@/view/layout/tourMixinsForLayout/addToPortfolioTourMixin.js';
    import Wizard from "@/view/pages/wizard/Wizard-2.vue";


    export default {
        name: "Layout",
        components: {
          Wizard,
          KTAside,
          KTHeader,
          KTHeaderMobile,
          KTFooter,
          Loader,
        },
        beforeMount() {
          // show page loading

          // initialize html element classes
          HtmlClass.init(this.layoutConfig());
        },
        mixins: [tickerInputTextTourMixin, siteTourMixin, savingTabsTourMixin, addToPortfolioTourMixin],
        mounted: function () {
            if (this.showTour) {
                // console.log("starting tour.");
                this.$tours['siteTour'].start();

                fb.usersCollection
                        .doc(this.$store.state.user.email)
                        .update({"showTour": false})
                        .then(() => {
                            console.log('showTour updated.')
                        });
            }            
        },
        data: function () {
            return {
                didTourStartTab: false,
                tourCallbacks: {
                    onFinish: this.onTourFinish,
                    onStop: this.onTourFinish,
                    onSkip: this.onTourFinish
                }
            }
        },
        computed: {
            showTour(){
              return this.$store.state.user.showTour;
            },
            year(){
              return moment().format("YYYY");
            },
            ...mapGetters([
              "isAuthenticated",
              "breadcrumbs",
              "pageTitle",
              "layoutConfig",
            ]),
            loaderEnabled() {
              return true;
            },
            contentFluid() {
              return this.layoutConfig("content.width") === "fluid";
            },
            loaderLogo() {
              return process.env.BASE_URL + this.layoutConfig("loader.logo");
            },
            asideEnabled() {
              return !!this.layoutConfig("aside.self.display");
            },
            toolbarDisplay() {
              // return !!this.layoutConfig("toolbar.display");
              return true;
            },
            subheaderDisplay() {
              return !!this.layoutConfig("subheader.display");
            },
            activeModuleName() {
                let activeModuleName = this.$store.getters["activeModuleName"];
                // console.log("activeModuleName=", activeModuleName);
                return activeModuleName;
            },
            user() {
                return this.$store.state.user;
            }
        },
        methods: {
            nextStep() {
                console.log("nextStep() starting.");
                console.log('this.$refs=', this.$refs);
                // console.log('this.$refs["vue-tour"]=', this.$refs["vue-tour"]);
                // console.log('this.$refs["vue-tour"].nextStep=', this.$refs["vue-tour"].nextStep);
                this.$refs["tour"].nextStep();
            },
            switchToSavingTabsTour() {
                console.log('Switching to saving tabs tour.');
                this.$tours["siteTour"].stop();

                this.$refs.content.$refs.tabsBox.$refs.sidebar.sidebarClosed = false;
                this.$refs.content.$refs.tabsBox.$refs.sidebar.updateSelectedSidebarComponentToFirebase("chartSaving");

                this.$tours["savingTabsTour"].start();
            },
            switchToPortfolioTour() {
                console.log('Switching to Portfolio tour.');
                this.$tours["portfolioTour"].stop();

                //this.startPageTour();

                this.$store.commit(this.activeModuleName + '/setHideInput', false);

                let that = this;
                setTimeout(function () {
                    that.$refs.content.$refs.tabsBox.$refs['input-component'].tourClickHandler(that.activeModuleName);
                }, 100);
            },
            onTourFinish() {
                console.log("onTourFinish() starting. this.activeModuleName=", this.activeModuleName, " this.didTourStartTab=", this.didTourStartTab);
                 console.trace();
                this.$refs.content.$refs.tabsBox.$refs.sidebar.sidebarClosed = true;
                this.$refs.content.$refs.tabsBox.$refs.sidebar.updateSelectedSidebarComponentToFirebase(null);

                if (this.didTourStartTab && this.activeModuleName !== null) {
                    this.didTourStartTab = false;

                    console.log("this.$refs.content.$refs.tabsBox", this.$refs.content.$refs.tabsBox);
                    this.$refs.content.$refs.tabsBox.closeTab(this.activeModuleName);
                }
            },
        }
    };

    /*
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
//import KTSubheader from "@/view/layout/subheader/Subheader.vue";
//import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
//import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import * as fb from '@/firebase';
import moment from "moment";


export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    //KTSubheader,
    //sKTStickyToolbar,
    //KTScrollTop,
    Loader,
  },
  beforeMount() {
    // show page loading

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted: function () {
     if(["admin", "superadmin"].includes(this.$store.state.user.role) && this.showTour){
       console.log("starting tour.");
        this.$tours['myTour'].start();

     fb.usersCollection
       .doc(this.$store.state.user.email)
       .update({"showTour": false})
       .then(() => {
          console.log('showTour updated.')
      }); 
     }
  },
  methods: {},
  data: function() {

      return {
        componentName: "Layout",
        steps: [
          {
            target: '#v-step-0',  // We're using document.querySelector() under the hood
            header: {
              title: 'Get Started',
            },
            content: `Take a brief tour.`,
            params: {
              enableScrolling: false
            }
          }, 
          {
            target: '#v-step-1',
            content: 'Click this to see information about the current tab.',
            params: {
              placement: 'right',
              enableScrolling: false
            },
             
          },
          {
            target: '#v-step-2',
            content: 'Click here to add a tab.',
            params: {
                placement: 'top', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                enableScrolling: false
              }
          },
          {
            target: '#v-step-3',
            content: 'Click here to close the active tab.',
            params: {
                placement: 'right', 
                enableScrolling: false
              }
          },
          {
            target: '#v-step-4',  // We're using document.querySelector() under the hood
            content: `Save tabs here.`,
            params: {
                      placement: 'right',
                      enableScrolling: false
            },
            
          }
        ]
      }
  },
  computed: {
    showTour(){
      return this.$store.state.user.showTour;
    },
    year(){
      return moment().format("YYYY");
    },
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    
    loaderEnabled() {
      return true;
    },

   
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

   
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

   
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

   
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};*/
</script>